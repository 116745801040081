var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "cta-form-container" }, [
      _c("form", { attrs: { id: "cta-form", action: "" } }, [
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _c("div", { staticClass: "form-row" }, [
          _c("label", { staticClass: "form-label", attrs: { for: "reseni" } }, [
            _vm._v("Co Vás zajímá?")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "select-radio-buttons-container" },
            _vm._l(_vm.reseniList, function(reseni) {
              return _c(
                "label",
                {
                  key: reseni.id,
                  staticClass: "select-radio",
                  attrs: { for: reseni.id }
                },
                [
                  _c("div", {
                    staticClass: "select-radio-buttom",
                    class: [{ checked: reseni.checked }]
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { id: reseni.id, type: "checkbox" },
                    on: {
                      change: function($event) {
                        return _vm.vybratReseni(reseni)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "select-radio-buttom-label" }, [
                    _vm._v(_vm._s(reseni.label))
                  ])
                ]
              )
            }),
            0
          )
        ]),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _vm._m(5)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cta-form-heading" }, [
      _c("p", { staticClass: "cta-text-big" }, [
        _c("span", [_vm._v("Say Hi!")]),
        _vm._v(" and tell me "),
        _c("br"),
        _vm._v(" about your idea")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "cta-text-small" }, [
        _vm._v("Have a nice works? Reach out and let's chat.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row" }, [
      _c("label", { staticClass: "form-label", attrs: { for: "name" } }, [
        _vm._v("Jméno")
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-input",
        attrs: { type: "text", placeholder: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row" }, [
      _c("label", { staticClass: "form-label", attrs: { for: "email" } }, [
        _vm._v("Emailová adresa")
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-input",
        attrs: { type: "email", placeholder: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row" }, [
      _c("label", { staticClass: "form-label", attrs: { for: "company" } }, [
        _vm._v("Název společnosti")
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-input",
        attrs: { type: "text", placeholder: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row" }, [
      _c("label", { staticClass: "form-label", attrs: { for: "message" } }, [
        _vm._v("Zpáva")
      ]),
      _vm._v(" "),
      _c("textarea", {
        staticClass: "form-input",
        attrs: {
          name: "message",
          id: "",
          cols: "30",
          rows: "10",
          placeholder: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("button", { staticClass: "cta-btn" }, [_vm._v("Submit")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }