<template>
    <div>
        <div class="sluzba-intro" style="background-image: url('/theme/images/sluzby/img_eshop.jpg'); background-size: cover;">
            <div class="block-container heading-block">
                <h1>I design <br> interfaces<span class="red">.</span></h1>
            </div>
            <div class="block-padding-lr">
                <div class="line"></div>
            </div>
            <div class="block-container">
                <div class="intro-cols">
                    <div class="first-col">
                        <p>Multidisciplinary product designer specializing in systems, experience and interaction.Currently at Cash App </p>
                    </div>
                    <div class="second-col">
                        <p>I'm a designer currently based in Seattle, where I'm part of the design team at Cash App. As a multidisciplinary designer, I specialize in interaction, visual, brand, motion, and more. I'm passionate about crafting seamless user experiences and thrive on challenges that demand creativity and innovation.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="sluzby-reference-grid">
            <div 
                class="sluzby-reference-item reference-item-1 reference-item-padding-lr reference-item-padding-tb" 
                style="
                    background-image: url('/theme/images/sluzby/microwell.jpg');
                    background-position: bottom right;
                    background-size: cover;
                    background-repeat: no-repeat;
                "
            >
                <h2>Microwell</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non corrupti repudiandae reiciendis accusamus sunt tempora ex, magni quos minima libero, nisi blanditiis maiores repellat pariatur accusantium, quod asperiores eius! Rerum?</p>
                <!-- <img src="/theme/images/topestates/mockup_pt.png" alt=""> -->
            </div>
            <div class="sluzby-reference-item reference-item-2 reference-item-padding-lr reference-item-padding-tb">
                <h2>E-shop se sportovním oblečením ATEX</h2>
                <p>Brněnské firmě Atex se staráme o eshop více než 10 let. Novinkou je kompletní napojení na IS Business Central od Microsoftu.</p>
                <!-- <div> -->
                    <img src="/theme/images/sluzby/atex_shop.png" alt="">
                <!-- </div> -->
            </div>
            <div 
                class="sluzby-reference-item reference-item-3 reference-item-padding-lr reference-item-padding-tb"
                style="background-image: url('/theme/images/klienti/opice.jpg');"
            >
                <div class="reference-item-3-text">
                    <span class="sluzby-reference-label">Kare</span>
                    <h2>Kare e-shop</h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae explicabo suscipit nemo est! Possimus voluptatem id quas. Nulla libero illo quo aut architecto, vero eius, laborum natus minima, commodi quaerat.</p>
                </div>
                <div class="reference-item-3-img-wrapper">
                    <img src="/theme/images/klienti/kare.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            sluzba_id: {
                type: Number,
            },
        },
    }
</script>

<style lang="scss" scoped>
p{
    font-size: 18px;
    line-height: 1.6;
};
.sluzba-2-intro{
    // background-color: #cdcdcd;
    color: white;
    position: relative;
    .bgvid{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0px;
        left: 0px;
        z-index: -1;
        // opacity: 0.9;
    }
    h1{
        font-size: 92px;
        line-height: 1;
        font-weight: 600;
        .red{
            color: red;
        }
        @media screen and (max-width: 1600px) {
            font-size: 82px;
        }
    }
    .sluzba-2-intro-cols{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 60px;
    }
}

.block-container{
    padding: 92px;
}
.block-padding-lr{
    padding-left: 92px;
    padding-right: 92px;
}
.sluzba-intro{
    background-color: #020202;
    color: white;
    h1{
        font-size: 192px;
        line-height: 1;
        font-weight: 600;
        .red{
            color: red;
        }
        @media screen and (max-width: 1600px) {
            font-size: 120px;
        }
    }
}
.line{
    border-top: #252525 solid 1px ;
}
.intro-cols{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    .first-col{
        p{
            font-size: 42px;
            line-height: 1.3;
            max-width: 500px;

            @media screen and (max-width: 1600px) {
                font-size: 32px;
            }
        }
    }
    .second-col{
        p{
            font-size: 18px;
            line-height: 1.6;
            color: #888888;
            max-width: 500px;
        };
    }
}
.sluzby-2-reference{
    background-color: #cdcdcd;
    .sluzby-reference-item{
        border-top: solid 1px rgb(192, 192, 192);
        padding-right: 0px !important;
        .reference-item-layout{
            display: grid;
            grid-template-columns: 1.2fr 2fr;
            gap: 32px;
            padding-right: 0px;
            overflow: hidden;
            img{
                max-width: 120%;
            }
        }
    }
}

.reference-item-padding-lr{
    padding-left: 92px;
    padding-right: 92px;
}
.reference-item-padding-tb{
    padding-top: 92px;
    padding-bottom: 92px;
}
.sluzby-reference-label{
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 42px;
    opacity: .4;
}
.reference-item-1{
    grid-area: item1;
    // aspect-ratio: 1;
    background-color: #202020;
    color: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    img{
        margin-top: auto;
        margin-top: 60px;
        margin-left: -50%;
        margin-right: -15%;
    }
}
.reference-item-2{
    grid-area: item2;
    background-color:white;
    color: black;

    display: flex;
    flex-direction: column;

    width: 100%;
    overflow: hidden;
    padding-bottom: 0px;
    img{
        margin-top: auto;
        margin-top: 120px;
        margin-bottom: 160px;
        margin-left: -15%;
        margin-right: -15%;
    }
}
.reference-item-3{
    grid-area: item3;
    background-color: #bfbfbf;
    color: white;

    display: grid;
    grid-template-columns: 1.5fr 2fr;
    gap: 32px;
    padding-right: 0px;
    overflow: hidden;

    background-position: center;
    .reference-item-3-img-wrapper{
        text-align: right;
        img{
            width: 90%;
        }
    }
}
.reference-item-3-text{
    padding-right: 60px;
}
.sluzby-reference-grid{
    display: grid;
    grid-template-areas: 
    "item1 item2"
    "item3 item3";
    img{
        max-width: 100%;
    }
    .reference-item-3 img{
        max-width: 110%;
    }
    .reference-item-2 img,
    .reference-item-1 img{
        max-width: 250%;
    }
    @media screen and (max-width: 1400px) {
        grid-template-areas: 
        "item1"
        "item2"
        "item3";
    }
}
.sluzby-reference-item{
    h2{
        font-size: 62px;
        font-weight: 600;
        margin-bottom: 42px;
    }
    p{
        line-height: 1.6;
        font-size: 18px;
        opacity: .7;
    }
}
</style>