var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "div",
        {
          staticClass: "sluzba-intro",
          staticStyle: {
            "background-image": "url('/theme/images/sluzby/img_eshop.jpg')",
            "background-size": "cover"
          }
        },
        [
          _c("div", { staticClass: "block-container heading-block" }, [
            _c("h1", [
              _vm._v("I design "),
              _c("br"),
              _vm._v(" interfaces"),
              _c("span", { staticClass: "red" }, [_vm._v(".")])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "block-padding-lr" }, [
            _c("div", { staticClass: "line" })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "block-container" }, [
            _c("div", { staticClass: "intro-cols" }, [
              _c("div", { staticClass: "first-col" }, [
                _c("p", [
                  _vm._v(
                    "Multidisciplinary product designer specializing in systems, experience and interaction.Currently at Cash App "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "second-col" }, [
                _c("p", [
                  _vm._v(
                    "I'm a designer currently based in Seattle, where I'm part of the design team at Cash App. As a multidisciplinary designer, I specialize in interaction, visual, brand, motion, and more. I'm passionate about crafting seamless user experiences and thrive on challenges that demand creativity and innovation."
                  )
                ])
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "sluzby-reference-grid" }, [
        _c(
          "div",
          {
            staticClass:
              "sluzby-reference-item reference-item-1 reference-item-padding-lr reference-item-padding-tb",
            staticStyle: {
              "background-image": "url('/theme/images/sluzby/microwell.jpg')",
              "background-position": "bottom right",
              "background-size": "cover",
              "background-repeat": "no-repeat"
            }
          },
          [
            _c("h2", [_vm._v("Microwell")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Non corrupti repudiandae reiciendis accusamus sunt tempora ex, magni quos minima libero, nisi blanditiis maiores repellat pariatur accusantium, quod asperiores eius! Rerum?"
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "sluzby-reference-item reference-item-2 reference-item-padding-lr reference-item-padding-tb"
          },
          [
            _c("h2", [_vm._v("E-shop se sportovním oblečením ATEX")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Brněnské firmě Atex se staráme o eshop více než 10 let. Novinkou je kompletní napojení na IS Business Central od Microsoftu."
              )
            ]),
            _vm._v(" "),
            _c("img", {
              attrs: { src: "/theme/images/sluzby/atex_shop.png", alt: "" }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "sluzby-reference-item reference-item-3 reference-item-padding-lr reference-item-padding-tb",
            staticStyle: {
              "background-image": "url('/theme/images/klienti/opice.jpg')"
            }
          },
          [
            _c("div", { staticClass: "reference-item-3-text" }, [
              _c("span", { staticClass: "sluzby-reference-label" }, [
                _vm._v("Kare")
              ]),
              _vm._v(" "),
              _c("h2", [_vm._v("Kare e-shop")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae explicabo suscipit nemo est! Possimus voluptatem id quas. Nulla libero illo quo aut architecto, vero eius, laborum natus minima, commodi quaerat."
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "reference-item-3-img-wrapper" }, [
              _c("img", {
                attrs: { src: "/theme/images/klienti/kare.png", alt: "" }
              })
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }