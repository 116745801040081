<template>
    <div>
        <div class="sluzby-bg">
            <div class="sluzby-intro">
                <div class="container">
                    <!-- <div class="intro-bg-linear-gradient"></div> -->
                    <p class="intro-p-top">
                        Hello jsme reklamní agentura <br>
                        zajištující komplexni služby pro své klienty
                    </p>
                    <h1>
                        <span class="h1-line">
                            <span class="hp_h1_efekt">{{ headingDisplayText }}</span>
                        </span>
                    </h1>
                    <p class="text-rotujici">{{ subtitleDisplayText  }}</p>
                    <div class="intro-btn-container">
                        <a class="round-btn">
                            <div class="inner-bg"></div>
                            <div class="inner">
                                <span>Let's </span><span>talk</span>
                            </div>
                        </a>
                    </div>
                    <!-- <img src="theme/images/sluzby/print/print_intro.jpg" alt=""> -->
                </div>
            </div>
            <div class="sluzby-wrapper">
                <div class="container">
                    <ul class="sluzby-list">
                        <li 
                            v-for="sluzba in sluzby" 
                            :key="sluzba.id"
                            @click="zobrazitDetailSluzby(sluzba)"
                            :class="{'disabled': sluzba.disabled,}"
                            class="scroll-efekt"
                        >
                            <div class="sluzby-list-item">
                                <span class="sluzby-list-title">{{ sluzba.name }}</span>
                                <span 
                                    v-if="sluzba.tags"
                                    class="sluzby-list-tags"
                                >
                                    <span
                                        v-for="tag in sluzba.tags"
                                        :key="tag"
                                        class="sluzby-list-tag"
                                    >
                                    {{ tag }} 
                                    </span>
                                </span>
                                <span class="tag-pripravujeme-container" v-if="sluzba.disabled">
                                    <span  class="tag-pripravujeme">Připravujeme prezentaci</span>
                                </span>
                                <span class="arrow-container" v-else>
                                    <div class="arrow_svg_tr_container">
                                        <svg class="arrow1_svg_tr" xmlns="http://www.w3.org/2000/svg" height="70px" viewBox="0 -960 960 960" width="70px" fill="#5f6368"><path d="M686-450H160v-60h526L438-758l42-42 320 320-320 320-42-42 248-248Z"/></svg>
                                        <svg class="arrow2_svg_tr" xmlns="http://www.w3.org/2000/svg" height="70px" viewBox="0 -960 960 960" width="70px" fill="#5f6368"><path d="M686-450H160v-60h526L438-758l42-42 320 320-320 320-42-42 248-248Z"/></svg>
                                    </div>
                                    <!-- <svg class="arrow_svg_transition" xmlns="http://www.w3.org/2000/svg" height="70px" viewBox="0 -960 960 960" width="70px" fill="#5f6368"><path d="M686-450H160v-60h526L438-758l42-42 320 320-320 320-42-42 248-248Z"/></svg> -->
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <!-- <p>{{ JSON.parse(sluzby_list) }}</p>
                <p>{{ sluzby }}</p> -->
            </div>
        </div>
        <div id="detail-sluzby-side-shadow"></div>
        <div id="detail-sluzby-side" class="detail-sluzby">
            <div id="detail-sluzby-side-wrapper">
                <div id="detail-sluzby-controls-container">
                    <div id="detail-sluzby-controls" class="detail-sluzby-controls">
                        <div 
                            style="position:relative;width:20px;height: 20px;"
                            @click="skrytDetalSluzby()"
                            class="detail-sluzby-close-btn"
                        >
                            <span class="ox-toggler__bar" style="position:absolute; transform: rotate(-45deg);top:50%;left:0;"></span>
                            <span class="ox-toggler__bar" style="position:absolute; transform: rotate(45deg);top:50%;left:0;"></span>
                        </div>
                    </div>
                </div>
                <sluzba-detail :sluzba="this.detailSluzby" :sluzbaSide="true"></sluzba-detail>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import SluzbaDetail from './SluzbaDetail.vue';
import Footer from './Footer.vue';

    export default {
        mounted() {
            this.displayText(); 
            setTimeout(() => {
                this.rotateTexts();
            }, 500);
            // let sideContainer = document.getElementById('detail-sluzby-side')
            // let closeBtn = document.getElementById('detail-sluzby-controls')
            // sideContainer.addEventListener('scroll', () => {
            //     // Aktualizace pozice tlačítka při scrollování
            //     closeBtn.style.top = sideContainer.scrollTop + 'px';
            // })
        },
        components: { SluzbaDetail, Footer },
        // props: {
        //     sluzby_list: {
        //         type: Object,
        //         // default: {
        //         //     name: "",
        //         // }
        //     },
        // },
        data() {
            return {
                headingText : "Přes 10 let",
                headingDisplayText : "",
                subtitles: ["se specializujeme na", "tvoříme", "umíme"],
                subtitleDisplayText : "",
                sluzby: [
                    {   
                        id: 1,
                        name: "Aplikace a Appky",
                        url: "aplikace-a-Appky",
                        disabled: true
                    },
                    {   
                        id: 2,
                        name: "Automatizace",
                        url: "automatizace",
                        disabled: false
                    },
                    {   
                        id: 3,
                        name: "Brand a Identita",
                        url: "brand-a-identita",
                        disabled: true
                    },
                    {   
                        id: 4,
                        name: "E-shopy",
                        url: "e-shopy",
                        disabled: false
                    },
                    {   
                        id: 5,
                        name: "Integrace",
                        url: "integrace",
                        disabled: true
                    },
                    {   
                        id: 6,
                        name: "Logo a Logotyp",
                        url: "logo-a-logotyp",
                        disabled: true
                    },
                    {   
                        id: 7,
                        name: "Microsites",
                        url: "microsites",
                        disabled: true
                    },
                    {   
                        id: 8,
                        name: "Obalový design",
                        url: "obalovy-design",
                        disabled: true
                    },
                    {   
                        id: 9,
                        name: "Obsahová strategie",
                        url: "obsahova-strategie",
                        disabled: true,
                        tags:[
                            'Novinka',
                            'Valuepack'
                        ]
                    },
                    {   
                        id: 10,
                        name: "Print",
                        url: "print",
                        disabled: false
                    },
                    {   
                        id: 11,
                        name: "SEO",
                        url: "seo",
                        disabled: true
                    },
                    {   
                        id: 12,
                        name: "Weby",
                        url: "weby",
                        disabled: true
                    },
                ],
                detailSluzby: null,
            }
        },
        methods: {
            displayText() {
                let index = 0;
                let intervalSubtitle = setInterval(() => {
                    if (index < this.headingText.length) {
                        this.headingDisplayText += this.headingText[index];
                        index++;
                    } else {
                        clearInterval(intervalSubtitle);
                    }
                }, 50);
            },
            rotateTexts(){
                let index = 0;
                let indexSubtitle = 0;
                const intervalFunction = () => {
                    this.subtitleDisplayText = ""
                    let intervalId = setInterval(() => {
                        if (index < this.subtitles[indexSubtitle].length) {
                            this.subtitleDisplayText += this.subtitles[indexSubtitle][index];
                            index++;
                        } else {
                            clearInterval(intervalId);
                            index = 0;
                            indexSubtitle++;
                            if (indexSubtitle >= this.subtitles.length) {
                                indexSubtitle = 0;  
                            }
                            setTimeout(() => {
                                intervalFunction();
                            }, 2000); 
                        }
                    }, 50);
                };
                intervalFunction();  
            },
            zobrazitDetailSluzby(sluzba) {
                this.detailSluzby = sluzba;
                if(!sluzba.disabled){
                    window.history.replaceState(null, null, `/${sluzba.url}`)
                    document.getElementById("detail-sluzby-side").classList.add("active")
                    document.getElementById("detail-sluzby-side-shadow").classList.add("active")
                    document.body.style.overflow = "hidden";
                }
                if(sluzba.id == 4){
                    document.querySelectorAll('#detail-sluzby-side .detail-sluzby-close-btn .ox-toggler__bar').forEach((line) => {
                        line.style.backgroundColor = "white"
                    })
                }else{
                    document.querySelectorAll('#detail-sluzby-side .detail-sluzby-close-btn .ox-toggler__bar').forEach((line) => {
                        line.style.backgroundColor = "white"
                    })
                }
                setTimeout(() => {
                    let sideContainer = document.getElementById('detail-sluzby-side-wrapper')
                    let sideControlContainer = document.getElementById('detail-sluzby-controls-container')
                    sideControlContainer.style.height = sideContainer.offsetHeight + "px"
                    console.log(sideContainer.offsetHeight);
                }, 100);
            },
            skrytDetalSluzby(){
                window.history.pushState(null, null, `/sluzby`)
                document.getElementById("detail-sluzby-side").classList.remove("active")
                document.getElementById("detail-sluzby-side-shadow").classList.remove("active")
                document.body.style.overflow = "scroll";
            }
        },
    }
</script>

<style lang="scss" scoped>
.sluzby-bg{
    // background-color: rgb(39, 38, 43);
    // background-color: black;
    background-color: #212529;
    color: white;
}
.sluzby-intro{
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 600px;
    height: calc(100vh - 62px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: radial-gradient(at center center, #2c3137 0%, #21252900 70%);
    .intro-bg-linear-gradient{
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        background: linear-gradient(black, rgba(0,0,0,0));
        z-index: -1;
    }
    .text-rotujici{ 
        min-height: 130px;
        font-size: 130px;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 1;
        @media screen and (max-width: 1440px) {
            min-height: 110px;
            font-size: 110px;
        }
    }
    .intro-p-top{
        font-weight: 600;
        color: RGB(130, 131, 132);
    }
    h1{
        font-size: 130px;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 1;
        @media screen and (max-width: 1440px) {
            font-size: 110px;
        }
        .h1-line{
            display: flex;
            align-items: center;
            gap: 60px;
        }
    }
    p{
        font-size: 32px;
    }
    .intro-btn-container{
        margin-top: 60px;
    }
    a.round-btn{
        display: flex;
        position: relative;
        font-size: 13px; 
        font-weight: 600;
        width: 120px;
        height: 120px;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 999px;
        color: white;
        cursor: pointer;
        text-decoration: none;
        line-height: 1.3;
        .inner{
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            align-items: center;
            border-radius: 999px;
            z-index: 2;
        }
        .inner-bg{
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0%;
            height: 0%;
            background-color: white;
            border-radius: 999px;
            z-index: 1;
            transition: all .4s;
        }
        &:hover{
            // background-color: #ED6217;
            color: black;
            .inner-bg{
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
            }
        }
    }
    img{
        width: 100%;
        margin-top: 120px;
        object-fit: cover;
        aspect-ratio: 16 / 9;
    }
}
.sluzby-list{
    list-style: none;
    padding: 0px;
    margin: 0px auto;
    padding-bottom: 90px;
    li{
        color: white;
        width: 100%;
        border-bottom: solid 1px rgba(255, 255, 255, 0.2);
        padding: 40px 0px;
        cursor: pointer;
        .sluzby-list-item{
            display: flex;
        }
        .sluzby-list-title{
            font-size: 80px;
            font-weight: 600;
        }
        &.disabled{
            .sluzby-list-title{
                // opacity: .35;
            }
            cursor:auto;
        }
    }
    li:first-child{
        padding-top: 0px;
    }
}
.sluzby-list-tags{
    margin-left: 20px;
    margin-top: 30px;
    display: flex;
    gap: 5px;
    .sluzby-list-tag{
        font-size: 16px;
        font-weight:600;
        line-height: 1.33;
        padding: 1px 7px;
        color: white;
        background-color: rgb(117, 0, 128);
        align-self: flex-start;
    }
}
.tag-pripravujeme-container{
    margin-left: auto;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: opacity .3s;
}
.arrow-container{
    margin-left: auto;
    display: flex;
    align-items: center;
}
.sluzby-list li:hover .tag-pripravujeme-container{
    opacity: 1;
}
.tag-pripravujeme{
    font-size: 16px;
        font-weight:600;
        line-height: 1.33;
        padding: 1px 7px;
        color: white;
        background-color: rgba(170, 170, 175, 0.5);
}
#detail-sluzby-side-shadow{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(200, 200, 205, 0);
    display: none;
    backdrop-filter: blur(0px);
    // transition: background-color 1s;
    &.active{
        display: block;
        background-color: rgba(200, 200, 205, 0.5);
        backdrop-filter: blur(10px);
    }
}
.detail-sluzby{
    overflow-y: scroll;
    position: fixed;
    z-index: 10;
    top: 0px;
    right: 0px;
    width: 70vw;
    max-width: 1600px;
    height: 100vh;
    background-color: white;
    transform: translateX(100%);
    transition: all .3s;
    @media screen and (max-width: 992px) {
        width: 100vw;
    }
    // padding: 30px 90px;
    &.active{
        transform: translateX(0px);
    }
    #detail-sluzby-controls-container{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 40px;
        height: 100%;
    }
    .detail-sluzby-controls{
        position: sticky;
        top: 16px;
        margin-bottom: 16px;
        margin-top: 16px;
        padding-left: 36px;
        z-index: 100;
    }
    .detail-sluzby-close-btn{
        cursor: pointer;
    }
}
.arrow_svg_transition{
    transition: all .4s;
}
.arrow_svg_tr_container{
    width: 70px;
    height: 70px;
    // border: solid 1px red;
    position: relative;
    overflow: hidden;
}
.arrow1_svg_tr{
    position: absolute;
    top: 0px;
    left: 0px;   
    transition: all .3s;
}
.arrow2_svg_tr{
    position: absolute;
    top: 0px;
    left: 0px;   
    transform: translateX(-70px);
    transition: all .3s;
    fill: white !important;
}
.sluzby-list li:hover{
    .arrow_svg_transition{
        transform: translateX(20px);
        fill: white !important;
    }
    .arrow2_svg_tr{ 
        transform: translateX(-0px);
    }
    .arrow1_svg_tr{ 
        transform: translateX(70px);
    }
} 

</style>