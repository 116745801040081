<template>
    <div style="overflow: hidden;">
        <div id="print-intro">
            <img class="intro-bg-img" src="/theme/images/sluzby/print/img_main_print.jpg" alt="">
            <div class="intro-text-container">
                <h1>Tiskoviny <br><span>Print</span></h1>
                <div class="intro-p left">
                    <p>Atex obléká olympioniky do dresů, my jsme zase oblékli stránky ATEXu do nového designu. Kvalitní týmové oblečení teď můžete objednávat na webu, který vyšel z naší dílny.</p>
                    <p>Atex obléká olympioniky do dresů, my jsme zase oblékli stránky ATEXu do nového designu. Kvalitní týmové oblečení teď můžete objednávat na webu, který vyšel z naší dílny.</p>
                </div>
                <div class="intro-p right">
                    <p>Atex obléká olympioniky do dresů, my jsme zase oblékli stránky ATEXu do nového designu. Kvalitní týmové oblečení teď můžete objednávat na webu, který vyšel z naší dílny.</p>
                    <p>Atex obléká olympioniky do dresů, my jsme zase oblékli stránky ATEXu do nového designu. Kvalitní týmové oblečení teď můžete objednávat na webu, který vyšel z naší dílny.</p>
                </div>
            </div>
        </div>
        <div id="print-reference">
            <div class="print-seznam-wrapper">
                <h2>Co nabízíme</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repudiandae, qui sequi obcaecati sunt quas ad.</p>
                <div class="print-seznam-ul-wrapper">
                    <ul class="print-seznam-ul">
                        <div>
                            <li>vizitky</li>
                            <li>letáky</li>
                            <li>etikety</li>
                            <li>nálepky</li>
                            <li>dárkové poukazy</li>
                            <li>pozvánky</li>
                            <li>vstupenky</li>
                            <li>plakáty</li>
                            <li>slohy</li>
                            <li>kalendáře</li>
                            <li>hlavičkové papíry</li>
                        </div>
                        <div>
                            <li>bloky</li>
                            <li>výroční zprávy</li>
                            <li>brožury</li>
                            <li>časopisy</li>
                            <li>katalogy</li>
                            <li>knihy</li>
                            <li>obalový materiál</li>
                            <li>roll upy</li>
                            <li>bannery</li>
                            <li>tapety na zeď</li>
                        </div>
                    </ul>
                </div>
            </div>
            <div class="print-reference-1">
                <!-- <img src="/theme/images/sluzby/print/07-Free-A4-A5-Magazine-Mockup.png" alt=""> -->
                <img src="/theme/images/sluzby/print/mc_bebeautiful_denisa.png" alt="">
                <div class="reference-1-grid">
                    <div>

                    </div>
                    <div class="print-reference-text">
                        <h2>Název <br> reference</h2>
                        <p>Atex obléká olympioniky do dresů, my jsme zase oblékli stránky ATEXu do nového designu. Kvalitní týmové oblečení teď můžete objednávat na webu, který vyšel z naší dílny.</p>
                    </div>
                </div>
            </div>
            <div class="print-reference-2">
                <!-- <img src="/theme/images/sluzby/print/Group 26.png" alt=""> -->
                <img src="/theme/images/sluzby/print/mc_bebeautiful_sandra.png" alt="">
            </div>
            <div class="print-reference-3">
                <!-- <img src="/theme/images/sluzby/print/07-Free-A4-A5-Magazine-Mockup.png" alt=""> -->
                <img src="/theme/images/sluzby/print/zivot_jako_hra.png" alt="">
                <div class="print-reference-3-grid">
                    <div></div>
                    <div class="print-reference-text">
                        <h2>Název reference</h2>
                        <p>Atex obléká olympioniky do dresů, my jsme zase oblékli stránky ATEXu do nového designu. Kvalitní týmové oblečení teď můžete objednávat na webu, který vyšel z naší dílny.</p>
                    </div>
                </div>
            </div>
            <div class="print-reference-4">
                <div class="print-reference-text">
                    <h2>Název reference</h2>
                    <p>Atex obléká olympioniky do dresů, my jsme zase oblékli stránky ATEXu do nového designu. Kvalitní týmové oblečení teď můžete objednávat na webu, který vyšel z naší dílny.</p>
                </div>
                <img src="/theme/images/sluzby/print/top_estates.png" alt="">
            </div>
            <div class="print-reference-5">
                <img src="/theme/images/sluzby/print/irebook.png" alt="">
                <div class="print-reference-5-grid">
                    <div></div>
                    <div class="print-reference-text">
                        <h2>IREBOOK</h2>
                        <p class="subtitle">Kniha firmní kulury</p>
                        <p>Atex obléká olympioniky do dresů, my jsme zase oblékli stránky ATEXu do nového designu. Kvalitní týmové oblečení teď můžete objednávat na webu, který vyšel z naší dílny.</p>
                    </div>
                </div>
            </div>
            <div class="print-reference-6">
                <img src="/theme/images/sluzby/print/cofee_katalog_2024.png" alt="">
                <div class="print-reference-text">
                    <h2>coFEE<br>Katalog</h2>
                    <p>Atex obléká olympioniky do dresů, my jsme zase oblékli stránky ATEXu do nového designu. Kvalitní týmové oblečení teď můžete objednávat na webu, který vyšel z naší dílny.</p>
                </div>
                <!-- <img src="/theme/images/sluzby/print/07-Free-A4-A5-Magazine-Mockup.png" alt=""> -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="scss" scoped>
.print-seznam-wrapper{
    padding: 0px 92px;
    background-color: #1B1C1F;
    color: white;
    padding-bottom: 120px;
    h2{
        font-size: 60px;
        max-width: 450px;
        line-height: 1;
        font-weight: 600;
        margin-bottom: 26px;
        color: white;
    }
    p{
        max-width: 450px;
        color: white;
        color: white;
        margin-bottom: 28px;
        font-size: 18px;
        line-height: 1.6;
    }
    .print-seznam-ul{
        list-style: none;
        padding-left: 0px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 120px;
        li{
            font-size: 22px;
            border-bottom: solid 1px rgba(255, 255, 255, 0.2);
            padding: 8px 0px;
        }
    }
}
.print-reference-1{
    position: relative;
    .reference-1-grid{
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        background: linear-gradient( #1B1C1F ,#595F6B) ;
        align-items: center;
    }
    img{
        position: absolute;
        left: 0px;
        top: -35%;
        width: 100%;
    }
    .print-reference-text{
        padding-top: 120px;
        padding-bottom: 100px;
        margin-right: 32px;
        h2{
            font-size: 60px;
            max-width: 450px;
            line-height: 1;
            font-weight: 600;
            margin-bottom: 26px;
            color: white;
        }
        p{
            max-width: 450px;
            color: white;
        }
    }
}
.print-reference-2{
    background: linear-gradient( #595F6B, #768096) ;
    img{
        width: 100%;
    }
}
.print-reference-3{
    background: linear-gradient( #768096, #B4B9C3) ;
    position: relative;
    img{
        width: 100%;
    }
    .print-reference-3-grid{
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        position: absolute;
        left: 0px;
        top: 0px;
        height: 100%;
        width: 100%;
        align-items: center;
    }
    .print-reference-text{
        padding-top: 120px;
        padding-bottom: 100px;
        margin-right: 32px;
        h2{
            font-size: 60px;
            max-width: 400px;
            line-height: 1;
            font-weight: 600;
            margin-bottom: 26px;
            color: black;
        }
        p{
            max-width: 400px;
            color: black;
        }
    }
}
.print-reference-4{
    background: linear-gradient( #B4B9C3, #D1CEC8) ;
    img{
        width: 100%;
    }
    .print-reference-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 60px;
        h2{
            font-size: 60px;
            max-width: 500px;
            line-height: 1;
            font-weight: 600;
            margin-bottom: 26px;
            text-align: center;
        }
        p{
            max-width: 500px;
            text-align: center;
        }
    }
}
.print-reference-5{
    background: linear-gradient( #D1CEC8, #B9AE9D) ;
    position: relative;
    .print-reference-5-grid{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        display: grid;
        grid-template-columns: 1.3fr 1fr;
        align-items: center;
    }
    img{
        width: 100%;
    }
    .print-reference-text{
        h2{
            font-size: 80px;
            max-width: 450px;
            line-height: 1;
            font-weight: 600;
            margin-bottom: 0px;
        }
        .subtitle{
            font-size: 36px;
            opacity: .4;
            font-weight: 600;
            margin-bottom: 26px;
        }
        p{
            max-width: 450px;
        }
        margin-left: 32px;
    }
}
.print-reference-6{
    background: linear-gradient( #B9AE9D, #867A66) ;
    min-height: 800px;
    position: relative;
    padding-bottom: 100px;
    img{
        width: 100%;
    }
    .print-reference-text{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        padding-left: 10%;
        padding-top: 10%;
        h2{
            color: white;
            font-size: 80px;
            max-width: 400px;
            line-height: 1;
            font-weight: 600;
            margin-bottom: 32px;
        }
        p{
            color: white;
            max-width: 30%;
        }
        margin-left: 32px;
    }
}
#print-intro{
    position: relative;
    widows: 100%;
}
.intro-bg-img{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    object-fit: cover;
    width: 100%;
}
.intro-text-container{
    position: relative;
    z-index: 2;
    padding: 156px 92px;
    h1{
        font-size: 150px;
        font-weight: 600;
        color: white;
        line-height: 1;
        margin-bottom: 43px;
        span{
            opacity: .7;
        }
    }
    .intro-p{
        max-width: 400px;
        &.right{
            margin-left: auto;
            margin-top: 200px;
        }
        &.left{
        }
        p{
            color: white;
            margin-bottom: 28px;
            font-size: 18px;
            line-height: 1.6;
        }
    }
}
</style>