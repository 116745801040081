<template>
    <div>
        <div class="footer-container">
            <div class="footer-block-1">
                <div class="socialni-site">
                    <a href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.537" height="18.537" viewBox="0 0 18.537 18.537">
                            <g id="instagram" transform="translate(1 1)">
                                <path id="Path_25" data-name="Path 25" d="M7.134,3H15.4a4.134,4.134,0,0,1,4.134,4.134V15.4A4.134,4.134,0,0,1,15.4,19.537H7.134A4.134,4.134,0,0,1,3,15.4V7.134A4.134,4.134,0,0,1,7.134,3Z" transform="translate(-3 -3)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                                <path id="Path_26" data-name="Path 26" d="M18.644,14.757a3.307,3.307,0,1,1-2.786-2.786,3.307,3.307,0,0,1,2.786,2.786Z" transform="translate(-7.068 -7.01)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                                <path id="Path_27" data-name="Path 27" d="M26.25,9.75h0" transform="translate(-13.434 -6.029)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                            </g>
                        </svg>
                    </a>
                    <a href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17.971" height="17.971" viewBox="0 0 17.971 17.971">
                            <path id="Icon_awesome-linkedin" data-name="Icon awesome-linkedin" d="M16.687,0H1.28A1.289,1.289,0,0,0,0,1.3V16.675a1.289,1.289,0,0,0,1.28,1.3H16.687a1.293,1.293,0,0,0,1.284-1.3V1.3A1.293,1.293,0,0,0,16.687,0ZM5.431,15.4H2.768V6.827H5.435V15.4ZM4.1,5.656A1.544,1.544,0,1,1,5.644,4.112,1.545,1.545,0,0,1,4.1,5.656ZM15.415,15.4H12.752V11.232c0-.995-.02-2.274-1.384-2.274-1.388,0-1.6,1.083-1.6,2.2V15.4H7.1V6.827H9.659V8H9.7a2.805,2.805,0,0,1,2.523-1.384c2.7,0,3.2,1.777,3.2,4.088Z" fill="#000"></path>
                        </svg>
                    </a>
                    <a href="#">
                        <svg id="facebook" xmlns="http://www.w3.org/2000/svg" width="8.013" height="14.961" viewBox="0 0 8.013 14.961">
                            <path id="facebook-f" d="M9.1,8.416l.416-2.708h-2.6V3.951A1.354,1.354,0,0,1,8.441,2.488H9.623V.183A14.4,14.4,0,0,0,7.526,0,3.306,3.306,0,0,0,3.988,3.644V5.708H1.609V8.416H3.988v6.546H6.915V8.416Z" transform="translate(-1.609 0)" fill="#000"></path>
                        </svg>
                    </a>
                </div>
                <p class="footer-text-big">Want to create something <br><span>awesome?</span></p>
                <button class="cta-btn" @click="zobrazitCTA()">Let's Talk 
                    <span class="arrow_icon">
                        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.75 12H20.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M13.5 5.25L20.25 12L13.5 18.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            zobrazitCTA() {
                document.getElementById("cta-side").classList.add("active")
                // document.getElementById("detail-sluzby-side-shadow").classList.add("active")
                document.body.style.overflow = "hidden";
                setTimeout(() => {
                    let sideContainer = document.getElementById('cta-side-wrapper')
                    let sideControlContainer = document.getElementById('cta-side-controls-container')
                    sideControlContainer.style.height = sideContainer.offsetHeight + "px"
                    console.log(sideContainer.offsetHeight);
                }, 100);
            },
            skrytCTA(){
                window.history.pushState(null, null, `/sluzby`)
                document.getElementById("cta-side").classList.remove("active")
                // document.getElementById("detail-sluzby-side-shadow").classList.remove("active")
                document.body.style.overflow = "scroll";
            }
        },
    }
</script>

<style lang="scss" scoped>
.footer-container{
    .socialni-site{
        display: flex;
        gap: 90px;
        justify-content: center;
        align-items: center;
    }
    .footer-text-big{
        font-size: 104px;
        line-height: 1;
        font-weight: 600;
        text-align: center;
        span{
            color: #7a7d8d;
        }
    }
    .footer-block-1{
        background-color: #EBEBEB;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 60px;
        padding: 60px 0px;
    }
    .cta-btn{
        border: none;
        background-color: black;
        color: white;
        border-radius: 60px;
        padding: 16px 42px;
        font-size: 24px;
        display: flex;
        gap: 12px;
        transition: all .3s;
        .arrow_icon{
            width: 25px;
            height: 25px;
        }
        &:hover{
            background-color: #7a7d8d;
        }
    }
}
</style>