<template>
    <div>
        <div v-if="sluzba">
            <!-- <div v-if="sluzba.id == 2 || sluzba.id == 4">
                <sluzba-intro :sluzba_id="sluzba.id"></sluzba-intro>
                <sluzba-reference :sluzba_id="sluzba.id"></sluzba-reference>
            </div> -->
            <sluzba-automatizace-detail v-if="sluzba.id == 2"></sluzba-automatizace-detail>
            <sluzba-eshopy-detail v-if="sluzba.id == 4"></sluzba-eshopy-detail>
            <sluzba-print-detail v-if="sluzba.id == 10"></sluzba-print-detail>
            <CTA v-if="sluzbaSide"></CTA>
            <Footer v-if="!sluzbaSide"></Footer>
        </div>
    </div>
</template>

<script>
import SluzbaPrintDetail from './sluzby/SluzbaPrintDetail.vue';
import SluzbaAutomatizaceDetail from './sluzby/SluzbaAutomatizaceDetail.vue';
import SluzbaEshopyDetail from './sluzby/SluzbaEshopyDetail.vue';
import CTA from './CTA.vue'
import Footer from './Footer.vue'

    export default {
        components: { 
            CTA, Footer, SluzbaPrintDetail, SluzbaAutomatizaceDetail,
            SluzbaEshopyDetail, 
         },
        props: {
            sluzba: {
                type: Object,
                default: null
                // default: {
                //     title: ""
                // }
            },
            sluzbaSide: {
                default: false
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>