var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "sluzby-bg" }, [
        _c("div", { staticClass: "sluzby-intro" }, [
          _c("div", { staticClass: "container" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("h1", [
              _c("span", { staticClass: "h1-line" }, [
                _c("span", { staticClass: "hp_h1_efekt" }, [
                  _vm._v(_vm._s(_vm.headingDisplayText))
                ])
              ])
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-rotujici" }, [
              _vm._v(_vm._s(_vm.subtitleDisplayText))
            ]),
            _vm._v(" "),
            _vm._m(1)
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sluzby-wrapper" }, [
          _c("div", { staticClass: "container" }, [
            _c(
              "ul",
              { staticClass: "sluzby-list" },
              _vm._l(_vm.sluzby, function(sluzba) {
                return _c(
                  "li",
                  {
                    key: sluzba.id,
                    staticClass: "scroll-efekt",
                    class: { disabled: sluzba.disabled },
                    on: {
                      click: function($event) {
                        return _vm.zobrazitDetailSluzby(sluzba)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "sluzby-list-item" }, [
                      _c("span", { staticClass: "sluzby-list-title" }, [
                        _vm._v(_vm._s(sluzba.name))
                      ]),
                      _vm._v(" "),
                      sluzba.tags
                        ? _c(
                            "span",
                            { staticClass: "sluzby-list-tags" },
                            _vm._l(sluzba.tags, function(tag) {
                              return _c(
                                "span",
                                { key: tag, staticClass: "sluzby-list-tag" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(tag) +
                                      " \n                                "
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      sluzba.disabled
                        ? _c(
                            "span",
                            { staticClass: "tag-pripravujeme-container" },
                            [
                              _c("span", { staticClass: "tag-pripravujeme" }, [
                                _vm._v("Připravujeme prezentaci")
                              ])
                            ]
                          )
                        : _c("span", { staticClass: "arrow-container" }, [
                            _c(
                              "div",
                              { staticClass: "arrow_svg_tr_container" },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "arrow1_svg_tr",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      height: "70px",
                                      viewBox: "0 -960 960 960",
                                      width: "70px",
                                      fill: "#5f6368"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M686-450H160v-60h526L438-758l42-42 320 320-320 320-42-42 248-248Z"
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "svg",
                                  {
                                    staticClass: "arrow2_svg_tr",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      height: "70px",
                                      viewBox: "0 -960 960 960",
                                      width: "70px",
                                      fill: "#5f6368"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M686-450H160v-60h526L438-758l42-42 320 320-320 320-42-42 248-248Z"
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ])
                    ])
                  ]
                )
              }),
              0
            )
          ])
        ]),
        _vm._v(" "),
        _c("div")
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "detail-sluzby-side-shadow" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "detail-sluzby", attrs: { id: "detail-sluzby-side" } },
        [
          _c(
            "div",
            { attrs: { id: "detail-sluzby-side-wrapper" } },
            [
              _c("div", { attrs: { id: "detail-sluzby-controls-container" } }, [
                _c(
                  "div",
                  {
                    staticClass: "detail-sluzby-controls",
                    attrs: { id: "detail-sluzby-controls" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "detail-sluzby-close-btn",
                        staticStyle: {
                          position: "relative",
                          width: "20px",
                          height: "20px"
                        },
                        on: {
                          click: function($event) {
                            return _vm.skrytDetalSluzby()
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "ox-toggler__bar",
                          staticStyle: {
                            position: "absolute",
                            transform: "rotate(-45deg)",
                            top: "50%",
                            left: "0"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "ox-toggler__bar",
                          staticStyle: {
                            position: "absolute",
                            transform: "rotate(45deg)",
                            top: "50%",
                            left: "0"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("sluzba-detail", {
                attrs: { sluzba: this.detailSluzby, sluzbaSide: true }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "intro-p-top" }, [
      _vm._v("\n                    Hello jsme reklamní agentura "),
      _c("br"),
      _vm._v(
        "\n                    zajištující komplexni služby pro své klienty\n                "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "intro-btn-container" }, [
      _c("a", { staticClass: "round-btn" }, [
        _c("div", { staticClass: "inner-bg" }),
        _vm._v(" "),
        _c("div", { staticClass: "inner" }, [
          _c("span", [_vm._v("Let's ")]),
          _c("span", [_vm._v("talk")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }