<template>
    <div>
        <div class="cta-form-heading">
            <p class="cta-text-big"><span>Say Hi!</span> and tell me <br> about your idea</p>
            <p class="cta-text-small">Have a nice works? Reach out and let's chat.</p>
        </div>
        <div class="cta-form-container">
            <form  id="cta-form" action="">
                <div class="form-row">
                    <label class="form-label" for="name">Jméno</label>
                    <input class="form-input" type="text" placeholder="">
                </div>
                <div class="form-row">
                    <label class="form-label" for="email">Emailová adresa</label>
                    <input class="form-input" type="email" placeholder="">
                </div>
                <div class="form-row">
                    <label class="form-label" for="company">Název společnosti</label>
                    <input class="form-input" type="text" placeholder="">
                </div>
                <div class="form-row">
                    <label class="form-label" for="reseni">Co Vás zajímá?</label>
                    <div class="select-radio-buttons-container">
                        <label v-for="reseni in reseniList" :key="reseni.id" :for="reseni.id" class="select-radio">
                            <div class="select-radio-buttom" :class="[ { checked: reseni.checked }]"></div>
                            <input :id="reseni.id" type="checkbox" @change="vybratReseni(reseni)">
                            <span class="select-radio-buttom-label">{{ reseni.label }}</span>
                        </label>
                    </div>
                </div>
                <div class="form-row">
                    <label class="form-label" for="message">Zpáva</label>
                    <textarea class="form-input" name="message" id="" cols="30" rows="10" placeholder=""></textarea>
                </div>
                <div>
                    <button class="cta-btn">Submit</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                reseniList: [
                    { id: 'reseni_1', label: 'Aplikace a Appky', checked: false },
                    { id: 'reseni_2', label: 'Automatizace', checked: false },
                    { id: 'reseni_3', label: 'Brand a Identita', checked: false },
                    { id: 'reseni_4', label: 'Web Development', checked: false },
                    { id: 'reseni_5', label: 'E-shopy', checked: false },
                    { id: 'reseni_6', label: 'Logo a Logotyp', checked: false },
                    { id: 'reseni_7', label: 'Microsites', checked: false },
                    { id: 'reseni_8', label: 'Obalový design', checked: false },
                    { id: 'reseni_9', label: 'Obsahová strategie', checked: false },
                    { id: 'reseni_10', label: 'Print', checked: false },
                    { id: 'reseni_11', label: 'SEO', checked: false },
                    { id: 'reseni_12', label: 'Weby', checked: false }
                ],
            }
        },
        methods: {
            vybratReseni(reseni) {
                reseni.checked = !reseni.checked;
            },
        },
    }
</script>

<style lang="scss" scoped>
#cta-form{
    padding-top: 72px;
    padding-bottom: 72px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    .select-radio-buttons-container{
        grid-column-gap: 12px;
        grid-row-gap: 12px;
        flex-wrap: wrap;
        display: flex;
    }
    .select-radio{
        color: black;
        letter-spacing: .32px;
        margin-bottom: 0;
        padding: 12px 24px;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        display: inline-block;
        position: relative;
        .select-radio-buttom{
            width: 100%;
            height: 100%;
            background-color: #f9fafb;
            border-radius: 100px;
            margin-top: 0;
            margin-left: 0;
            position: absolute;
            top: 0%;
            bottom: 0%;
            left: 0%;
            right: 0%;
            border: 1px solid #ccc;
            float: left;
            line-height: normal;
            box-sizing: border-box;
            color: black;
            letter-spacing: .32px;
            font-size: 16px;
            font-weight: 500;
            &.checked{
                background-color: #d1d5db;
                border: 1px solid black;
            }
        }
        .select-radio-buttom-label{
            z-index: 10;
            margin-bottom: 0;
            margin-left: auto;
            margin-right: auto;
            font-weight: 500;
            position: relative;
            cursor: pointer;
            display: inline-block;
            box-sizing: border-box;
            color: black;
            letter-spacing: .32px;
            font-size: 16px;
            line-height: 150%;
        }
        input{
            opacity: 0;
            position: absolute;
            z-index: -1;
            box-sizing: border-box;
            padding: 0;
            line-height: normal;
            letter-spacing: .32px;
            color: inherit;
            font: inherit;
            margin: 0;
        }
    }
    .form-row{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .form-label{
            color: black;
            letter-spacing: .32px;
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
        }
        .form-input{
            color: #333;
            height: auto;
            border-style: none none solid;
            border-width: 1px;
            border-color: black black black;
            letter-spacing: .02em;
            margin-bottom: 0;
            padding: 0 16px 16px;
            font-size: 16px;
            line-height: 150%;
        }
    }
    .cta-btn{
        border: none;
        background-color: black;
        color: white;
        border-radius: 60px;
        padding: 16px 42px;
        font-size: 24px;
        display: flex;
        gap: 12px;
        transition: all .3s;
        .arrow_icon{
            width: 25px;
            height: 25px;
        }
        &:hover{
            background-color: #7a7d8d;
        }
    }
}
.cta-form-heading{
    margin-top: 160px;
    .cta-text-small{
        font-size: 22px;
        text-align: center;
    }
    .cta-text-big{
        font-size: 104px;
        line-height: 1;
        font-weight: 600;
        text-align: center;
        span{
            color: #7a7d8d;
        }
        margin-bottom: 24px;
    }
}
.cta-form-container{
    max-width: 650px;
    margin: 0px auto;
}

</style>