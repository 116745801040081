import Vue from 'vue';
import VueRouter from 'vue-router'

import Sluzby from './components/Sluzby.vue'
import SluzbaDetail from './components/SluzbaDetail.vue'

console.log("sluzby.js");
// Vue.use(VueRouter)

// const routes = [
//   { path: '/sluzby', component: Sluzby },
//   { path: '/e-shopy', component: SluzbaDetail },
//   { path: '/automatizace', component: SluzbaDetail }
// ]

// const router = new VueRouter({
//   mode: 'history',
//   base: '/sluzby/',
//   routes
// })

new Vue({
  // router,
  components: {
    'sluzby': Sluzby,
    'sluzba-detail': SluzbaDetail,
  }
}).$mount('#sluzbyApp')