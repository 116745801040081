var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "sluzba-2-intro" }, [
      _c(
        "video",
        {
          staticClass: "bgvid",
          attrs: { playsinline: "", autoplay: "", muted: "", loop: "" },
          domProps: { muted: true }
        },
        [
          _c("source", {
            attrs: {
              src: "/theme/images/sluzby/automatizace.mp4",
              type: "video/mp4"
            }
          })
        ]
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1)
    ]),
    _vm._v(" "),
    _vm._m(2)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block-container heading-block" }, [
      _c("h1", [
        _vm._v("Cokoliv, co nemusíte "),
        _c("br"),
        _vm._v(" dělat, tak nedělejte"),
        _c("span", { staticClass: "red" }, [_vm._v(".")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block-container",
        staticStyle: { "padding-top": "120px" }
      },
      [
        _c("div", { staticClass: "sluzba-2-intro-cols" }, [
          _c("div"),
          _vm._v(" "),
          _c("div", { staticClass: "first-col" }, [
            _c("div", [
              _c("p", [
                _vm._v(
                  "Umíme najít opakující se úkoly a procesy. Analyzujeme je a hledáme způsoby, jak je zpracovat strojově a automatizovat."
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", [
              _c("p", [
                _vm._v(
                  "Zlepšujeme pracovní postupy a usnadňujeme práci spojením aplikací, o kterých třeba ani netušíte, že to jde. Proč? Protože to je ve svém důsledku vždy levnější..."
                )
              ])
            ])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sluzby-2-reference" }, [
      _c(
        "div",
        {
          staticClass:
            "sluzby-reference-item reference-item-padding-lr reference-item-padding-tb"
        },
        [
          _c("div", { staticClass: "reference-item-layout" }, [
            _c("h2", [_vm._v("Kare")]),
            _vm._v(" "),
            _c("div", [
              _c("img", {
                staticStyle: { "max-width": "100%" },
                attrs: { src: "/theme/images/sluzby/kare_tablet.png", alt: "" }
              })
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "sluzby-reference-item reference-item-padding-lr reference-item-padding-tb"
        },
        [
          _c("div", { staticClass: "reference-item-layout" }, [
            _c("h2", [_vm._v("APSS")]),
            _vm._v(" "),
            _c("div", { staticClass: "reference-img" }, [
              _c("img", {
                attrs: { src: "/theme/images/sluzby/kare.png", alt: "" }
              })
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "sluzby-reference-item reference-item-padding-lr reference-item-padding-tb"
        },
        [
          _c("div", { staticClass: "reference-item-layout" }, [
            _c("h2", [_vm._v("Atex")]),
            _vm._v(" "),
            _c("div", { staticClass: "reference-img" }, [
              _c("img", {
                attrs: { src: "/theme/images/sluzby/kare.png", alt: "" }
              })
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "sluzby-reference-item reference-item-padding-lr reference-item-padding-tb"
        },
        [
          _c("div", { staticClass: "reference-item-layout" }, [
            _c("h2", [_vm._v("SC Brno")]),
            _vm._v(" "),
            _c("div", { staticClass: "reference-img" }, [
              _c("img", {
                attrs: { src: "/theme/images/sluzby/kare.png", alt: "" }
              })
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "sluzby-reference-item reference-item-padding-lr reference-item-padding-tb",
          staticStyle: {
            "background-image": "url('/theme/images/sluzby/microwell_02.jpg')",
            "background-size": "cover",
            "aspect-ratio": "1344 / 804"
          }
        },
        [
          _c("div", { staticClass: "reference-item-layout" }, [
            _c("h2", [_vm._v("Microwell")]),
            _vm._v(" "),
            _c("div", { staticClass: "reference-img" })
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }